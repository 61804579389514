<template>
  <div class="activity-detail">
    <div class="activity-detail-wrapper manage-wrapper">
      <div class="activity-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="活动详情" name="first">
            <el-form ref="form" :model="form" label-width="180px">
              <!-- 活动名称 -->
              <el-form-item label="活动名称：" prop="name">
                {{form.name || '无'}}
              </el-form-item>
              <!-- 活动封面 -->
              <el-form-item label="活动封面：">
                <p v-if="form.cover === '' || !form.cover">无</p>
                <img :src="downloadURL + form.cover" alt="" class="cover" v-else />
              </el-form-item>
              <!-- banner图 -->
              <el-form-item label="banner图：">
                <p v-if="form.banner === '' || !form.banner">无</p>
                <img :src="downloadURL + form.banner" alt="" class="cover" v-else />
              </el-form-item>
              <!-- 报名时间段 -->
              {{form.activityTime}}
              <el-form-item label="活动时间：" :required="true" prop="activityTime">
                {{`${form.activityBeginTime} -- ${form.activityEndTime}`|| '未知'}}
              </el-form-item>
              <!-- 活动类型 -->
              <el-form-item label="活动类型：" prop="activityType" required>
                {{activityTypeMap[form.activityType] || '无'}}
              </el-form-item>
              <!-- 活动对象 -->
              <!-- <el-form-item label="活动对象：" prop="activityTarget" required>
                <span v-for="(item,index) in form.activityTarget" :key="index"
                  style="margin-right:10px;">{{activityTargetMap[item] || '无'}}</span>
              </el-form-item> -->
              <!-- 学科 -->
              <el-form-item label="学科：" prop="activityType">
                {{form.subjectName|| '无'}}
              </el-form-item>
              <!-- 所属项目 -->
              <el-form-item label="用户组：" :required="true">
                <el-cascader style="width:100%" :options="userGroupOptionList" v-model="form.userGroup"
                  :props="{ multiple: true , value: 'id',emitPath:false}" disabled></el-cascader>
              </el-form-item>
              <!-- 负责人 -->
              <!-- <el-form-item label="负责人：">
                {{form.initiatorName || '无'}}
              </el-form-item> -->
              <!-- 活动简介 -->
              <el-form-item label="活动简介：">
                {{form.introduction || '无'}}
              </el-form-item>
              <!-- 活动规则 -->
              <el-form-item label="活动规则：" class="tinymce">
                <p v-if="!form.detail">无</p>
                <ul class="file_list" v-if="form.detail">
                  <li class="csp" v-for="(item, index) in [{filename:form.detailFileName,md5:form.detail}]" :key="index"
                    @click="openFile(item)">
                    <span @click="openFile(item)">{{ item.filename }}</span>
                  </li>
                </ul>
              </el-form-item>
              <!-- 课程资源 -->
              <div v-if="form.activityType == 0">
                <el-form-item :label="`共读课程资源${index+1}：`" v-for="(item,index) in form.courseInfos" :key="index">
                  <p>{{item.title}}</p>
                  <span v-for="(each,index) in item.courses"
                    :key="index">{{each.name}}{{index == item.courses.length-1?'':'  ，'}}</span>
                </el-form-item>
                <el-form-item label="共读课程资源：" v-if="form.courseInfos.length == 0">
                  <span>暂无绑定课程</span>
                </el-form-item>
              </div>

              <!-- 按钮 -->
              <el-form-item class="el-form-item-btns" label-width="0">
                <el-button type="primary" @click="goEdit" v-if="showEditBtn">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import upload from './components/upload-rule.vue';

  export default {
    data() {
      return {
        activeName: "first",
        form: {},
        userGroupOptionList: [],
        activityTypeMap: {
          0: '大赛类',
          1: '教研类',
          2: '合作学习墙',
        },
        activityTargetMap: {
          0: '老师',
          1: '学生',
          2: '家长',
        },
        showEditBtn: true,
      };
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject,
      })
    },
    components: {
      upload,

    },
    beforeRouteEnter(to, from, next) {
      // console.log(from);
      next(vm => {
        if (from.name == 'mineActivity') {
          vm.showEditBtn = false;
        }
      });
    },
    watch: {},
    methods: {
      // 去编辑
      goEdit() {
        let id = this.$route.params.id
        this.$router.push({
          path: `/activity/edit/${id}?breadNumber=4`,
        })
      },
      // 获取活动详情信息
      async getActivityDetail() {
        let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
        // console.log(resData);
        this.form = resData.data;
        if (!this.form.hasOwnProperty('courseInfos')) {
          this.$set(this.form, 'courseInfos', []);
        }
      },
      // 获取用户组列表
      async getUserGroupOptionList() {
        let resData = await this.$Api.Form.getUserGroup(this.currentProject.id)
        // console.log('用户组列表', resData)
        this.userGroupOptionList = resData.data;
      },
      openFile(item) {
        window.open(this.downloadURL + item.md5)
      },
    },
    mounted() {
      this.getActivityDetail();
      this.getUserGroupOptionList();
    },

  };
</script>

<style lang="less" scoped>
  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: transparent;
    border-color: transparent;
  }

  /deep/ .el-input__suffix {
    display: none;
  }

  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: transparent;
    border-color: transparent;
    height: 200px;
  }

  /deep/ .el-cascader__tags {
    max-height: 200px;
    overflow-y: scroll;

    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
    }
  }

  .activity-detail .file_list {
    li {
      display: inline-block;
      line-height: 32px;

      margin: 0px 20px 5px 0;
      border-radius: 4px;
      padding: 0px 12px;

      color: #666;
      border: 1px solid rgba(204, 204, 204, .22);

      .deletefile {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }

      &:hover {
        background-color: rgba(221, 216, 216, 0.1);
      }
    }
  }
</style>